import { Modal as antModal } from 'antd';
import styled from 'styled-components';

import { metrics } from '../../assets/styles/commonStyle';
import { componentAdjustment } from '../Layout/common';

interface ModalProps {
  uppercase?: boolean;
  headerColor?: string;
  headerTextColor?: string;
  border?: string | number;
}
export const Modal = styled(antModal)<ModalProps>`
  top: 50px !important;
  ${componentAdjustment};

  .ant-modal-body {
    padding-left: 25px;
    padding-right: 25px;
  }
  .ant-modal-content {
    border-radius: ${props => props.border || 0}px;
  }
  .ant-modal-header {
    padding-left: 25px;
    padding-right: 25px;
    background: ${props => props.headerColor || '#f7f7f7'};
    color: ${props => props.headerTextColor || 'black'};
    border-radius: ${({ border }) => border && `${border}px ${border}px 0px 0px}`};
  }

  .ant-modal-title {
    color: ${props => props.headerTextColor || 'rgba(0, 0, 0, 0.85);'};
  }

  .ant-modal-footer {
    border: 0;
    border-top: 1px solid #e3e3e3;
    border-radius: ${({ border }) => border && `0px 0px ${border}px ${border}px}`};
    .ant-btn {
      text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
      padding: ${metrics.padding.base - 1}px ${metrics.padding.large - 1}px !important;
      height: auto !important;
    }
  }

  .ant-modal-close {
    right: 5px;
    color: ${props => props.headerTextColor || 'rgba(0, 0, 0, 0.45);'};
  }
`;

Modal.defaultProps = {
  uppercase: true
};

export default Modal;
