import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import produce from 'immer';
import { get, isEmpty, isEqual, map } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import _omit from 'lodash/omit';
import _pickBy from 'lodash/pickBy';
import uuid from 'uuid/v4';

import { fixDateWithTimezoneParam } from 'components/MagicTimezonePicker';
import { getStatic } from 'containers/Campaign/utils';
import { getOutcomeType } from 'containers/Inventory/components/LedgerList';
import { OUTCOME_TYPE } from 'pages/campaign/constant';
import { doesFilterHaveValue } from 'utils/audience';
import request from 'utils/request';

import { DURATION_TIMER } from '../../appConstants';
import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAIL,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAIL,
  GET_TAGS,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
  GET_LOYALTY_LIST,
  GET_LOYALTY_LIST_SUCCESS,
  GET_LOYALTY_LIST_FAIL,
  GET_REWARDS,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAIL,
  GET_AUDIENCES,
  GET_AUDIENCES_FAIL,
  GET_AUDIENCES_SUCCESS,
  GET_AUDIENCE_INFO,
  GET_AUDIENCE_INFO_SUCCESS,
  GET_AUDIENCE_INFO_FAILED,
  EDIT_CAMPAIGN,
  EDIT_CAMPAIGN_FAIL,
  EDIT_CAMPAIGN_SUCCESS,
  SEARCH_CAMPAIGN,
  SEARCH_CAMPAIGN_SUCCESS,
  SEARCH_CAMPAIGN_FAIL,
  SEARCH_REWARD,
  SEARCH_REWARD_SUCCESS,
  SEARCH_REWARD_FAIL,
  ORDER_CAMPAIGN,
  ORDER_CAMPAIGN_FAIL,
  ORDER_CAMPAIGN_SUCCESS,
  DEACTIVATE_CAMPAIGN,
  DEACTIVATE_CAMPAIGN_SUCCESS,
  DEACTIVATE_CAMPAIGN_FAIL,
  ACTIVATE_CAMPAIGN,
  ACTIVATE_CAMPAIGN_SUCCESS,
  ACTIVATE_CAMPAIGN_FAIL,
  GET_CONDITIONS,
  GET_CONDITIONS_SUCCESS,
  GET_CONDITIONS_FAIL
} from './constants';

export const campaignCreate = (payload, history, makerCheckerCampaign = false) => {
  const data = getCampaignPreparedToBeSent(payload);

  //remove display properties from games and only use locale version
  if (
    (data.campaign_type === 'game' && data.game_type !== 'quiz' && data.game_type !== 'survey') ||
    data.campaign_type === 'stamp' ||
    data.campaign_type === 'quest' ||
    data.campaign_type === 'raffle'
  ) {
    const dataKeys = Object.keys(data);
    const displayKeys = dataKeys.filter(k => k.startsWith('display_properties_'));
    displayKeys.forEach(k => {
      if (!!data[k]) {
        if (Object.keys(data[k]).length > 0) {
          data[k] = _merge({}, data.display_properties, data[k]);
        }
      }
    });
    delete data.display_properties;
  }

  return {
    type: CREATE_CAMPAIGN,
    endpoint: `/v4/dash/campaigns`,
    method: 'post',
    types: [CREATE_CAMPAIGN, CREATE_CAMPAIGN_SUCCESS, CREATE_CAMPAIGN_FAIL],
    notification: {
      loading: () => {
        message.loading('Creating a Campaign', DURATION_TIMER);
      },
      success: () => {
        if (makerCheckerCampaign) {
          message.success('Campaign is submitted for approval', DURATION_TIMER);
        } else {
          message.success('Campaign created', DURATION_TIMER);
        }
      },
      error: e => {
        if (e.message) {
          message.error(e.message, DURATION_TIMER);
        } else {
          message.error('Failed to Create Campaign', DURATION_TIMER);
        }
      }
    },
    instantAction: response => {
      if (history) {
        history.push(`/p/campaigns/show/${response.data.id}`);
      }
    },
    formdata: data
  };
};

export const campaignDeactivate = (payload, callback?) => {
  return {
    type: DEACTIVATE_CAMPAIGN,
    endpoint: `/v4/dash/campaigns/${payload.id}/deactivate`,

    method: 'patch',
    types: [DEACTIVATE_CAMPAIGN, DEACTIVATE_CAMPAIGN_SUCCESS, DEACTIVATE_CAMPAIGN_FAIL],
    notification: {
      loading: () => {
        message.loading('Deactivating Campaign...', DURATION_TIMER);
      },
      success: response => {
        if (response?.data?.request_type) {
          message.success('Campaign Deactivation Request Submitted For Approval!', DURATION_TIMER);
        } else {
          message.success('Deactivating Success...', DURATION_TIMER);
        }
      },
      error: () => {
        message.error('Failed to Activate !', DURATION_TIMER);
      }
    },
    callback: callback,
    nextAction: payload.page && payload.page === 'list' ? campaignsGet() : {}
  };
};

export const campaignActivate = (payload, callback?) => {
  return {
    type: ACTIVATE_CAMPAIGN,
    endpoint: `/v4/dash/campaigns/${payload.id}/activate`,

    method: 'patch',
    types: [ACTIVATE_CAMPAIGN, ACTIVATE_CAMPAIGN_SUCCESS, ACTIVATE_CAMPAIGN_FAIL],
    notification: {
      loading: () => {
        message.loading('Activating Campaign...', DURATION_TIMER);
      },
      success: response => {
        if (response?.data?.request_type) {
          message.success('Campaign Activation Request Submitted For Approval!', DURATION_TIMER);
        } else {
          message.success('Activating Success...', DURATION_TIMER);
        }
      },
      error: () => {
        message.error('Failed to Activate !', DURATION_TIMER);
      }
    },
    callback,
    nextAction: payload.page && payload.page === 'list' ? campaignsGet() : {},
    formdata: payload
  };
};

const flatten = (obj: Record<string, any>, prefix = '') => {
  if (!obj) return {};

  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    const value = obj[k];
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      Object.assign(acc, flatten(value, pre + k));
      return acc;
    } else {
      acc[pre + k] = value;
      return acc;
    }
  }, {});
};

const getDiffData = (campaign: any, toUpdate: any) => {
  const diff = {};

  const flattenCampaign = flatten(campaign);
  const flattenToUpdate = flatten(toUpdate);

  const keys = ['categories', 'catalogs', 'labels', 'tags'];
  for (const key of keys) {
    flattenCampaign[key] = map(flattenCampaign[key], 'id');
  }

  /*
   * map tags to tag_ids
   */
  flattenCampaign['tag_ids'] = map(flattenCampaign['tags'], 'id');

  Object.keys(flattenToUpdate).forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(flattenCampaign, key)) {
      return;
    }
    if (!isEqual(flattenCampaign[key], flattenToUpdate[key])) {
      diff[key] = flattenToUpdate[key];
    }
  });

  return diff;
};

export const campaignUpdate = (payload, history) => {
  const data = getCampaignPreparedToBeSent(payload.toUpdate);
  const { makerCheckerCampaign, campaign_request_id, isRoleTenantAdmin } = payload;

  fixDateWithTimezoneParam(data, 'start_date', 'start_time');
  fixDateWithTimezoneParam(data, 'end_date', 'end_time');

  // remove display_properties from games and only use locale version
  if (
    (data.campaign_type === 'game' && data.game_type !== 'quiz' && data.game_type !== 'survey') ||
    data.campaign_type === 'stamp' ||
    data.campaign_type === 'quest' ||
    data.campaign_type === 'raffle'
  ) {
    const dataKeys = Object.keys(data);

    const displayPropertiesLocaleKeys = dataKeys.filter(k => k.startsWith('display_properties_'));

    // here, the term static refers to non-textual, graphical data like images, colors and assets
    const displayProperties = getStatic(
      data.campaign_type,
      data.game_type,
      data.display_properties
    );

    displayPropertiesLocaleKeys.forEach(dpLocale => {
      if (!!data[dpLocale] && Object.keys(data[dpLocale]).length > 0) {
        // remove the existing wedges from the display_properties_{locale}. STAR-754
        // since we are only interested in the wedge data from displayProperties.
        if (data[dpLocale].hasOwnProperty('wedges')) {
          data[dpLocale].wedges = [];
        }

        // merging is required because we don't store graphical data in the
        // display_properties_{locale}, only in display_properties.
        data[dpLocale] = _merge({}, data[dpLocale], displayProperties);
      }
    });

    delete data.display_properties;
  }

  if (data && data.code_generation_method === 'multiple') {
    if (!data.user_generated) {
      data.code_generation_method = 'multiple_with_user_upload';
    }
  }

  let requestConfig = {} as {
    method: string;
    endpoint: string;
    formdata: Record<string, any>;
    successMessage: string;
  };
  switch (true) {
    /* Update new changes for existing campaign request */
    case makerCheckerCampaign && !isEmpty(campaign_request_id) && isRoleTenantAdmin === false:
      requestConfig = {
        method: 'put',
        endpoint: `v4/dash/campaign_requests/${campaign_request_id}`,
        formdata: {
          properties: getDiffData(payload.currentCampaign, data)
        },
        successMessage: 'Campaign request updated'
      };
      break;
    /* Apply changes to the campaign for editing active campaign */
    case makerCheckerCampaign &&
      !campaign_request_id &&
      payload.currentCampaign.state !== 'draft' &&
      isRoleTenantAdmin === false:
      requestConfig = {
        method: 'patch',
        endpoint: `/v4/dash/campaigns/${payload.toUpdate.id}/apply_changes`,
        formdata: {
          properties: getDiffData(payload.currentCampaign, data)
        },
        successMessage: 'Campaign is submitted for approval'
      };
      break;
    default:
      requestConfig = {
        method: 'put',
        endpoint: `/v4/dash/campaigns/${data.id}`,
        formdata: data,
        successMessage: 'Campaign updated'
      };
  }

  return {
    type: EDIT_CAMPAIGN,
    endpoint: requestConfig.endpoint,
    method: requestConfig.method,
    types: [EDIT_CAMPAIGN, EDIT_CAMPAIGN_SUCCESS, EDIT_CAMPAIGN_FAIL],
    notification: {
      loading: () => {
        message.loading('Updating campaign');
      },
      success: () => {
        message.success(requestConfig.successMessage, DURATION_TIMER);
      },
      error: (e: Error) => {
        if (e.message) {
          message.error(e.message, DURATION_TIMER);
        } else {
          message.error('Error updating campaign', DURATION_TIMER);
        }
      }
    },
    instantAction: response => {
      if (data.page) {
        if (data.page === 'list') {
        } else {
          history.push(`/p/campaigns/show/${data.id}`);
        }
      } else {
        if (response?.data?.request_type) {
          const recordId = response?.data?.campaign?.id;
          const campaignRequestId = response?.data?.id;
          const creatorId = response?.data?.creator_id;

          if (recordId && campaignRequestId && creatorId) {
            const state = {
              campaignRequestId: campaignRequestId,
              campaignCreatorId: creatorId
            };
            history.push({
              pathname: `/p/campaigns/show/${recordId}/campaign_request/${campaignRequestId}`,
              state
            });
          } else {
            history.push(`/p/campaigns/show/${data.id}`);
          }
        } else {
          history.push(`/p/campaigns/show/${data.id}`);
        }
      }
    },
    formdata: requestConfig.formdata
  };
};

export const getPopulatedOutcomes = async (outcomes = []) => {
  let preparedOutcomes = [];

  const sortedOutcomes = [...outcomes].sort((a, b) => a.id - b.id);

  const prizeSets = sortedOutcomes?.filter(_ => _.type === OUTCOME_TYPE.PRIZE_SET) || [];

  const prizeSetResponses = await Promise.all(
    prizeSets.map(prizeSet => request.get(`/v4/dash/prize_sets/${prizeSet.id}/items`))
  );
  const prizeSetItems = prizeSetResponses.map(res => res.data.data);

  const rewardIds = [];
  const loyaltyIds = [];

  for (const prizeSet of prizeSetItems) {
    for (const item of prizeSet) {
      if (
        [OUTCOME_TYPE.REWARD_CAMPAIGN, OUTCOME_TYPE.REWARD].includes(item.item_type || item.type)
      ) {
        rewardIds.push(item.item_id || item.id);
      } else {
        loyaltyIds.push(item.item_id || item.id);
      }
    }
  }

  const [{ data: rewards }, { data: loyalties }] = await Promise.all([
    rewardIds.length > 0
      ? request(`/v4/dash/rewards?id=${rewardIds.join(',')}`)
      : Promise.resolve({ data: [] }),
    loyaltyIds.length > 0
      ? request(`/v4/dash/loyalty_programs?id=${loyaltyIds.join(',')}`)
      : Promise.resolve({ data: [] })
  ]);

  const prizeSetItemsById = prizeSets.reduce(
    (acc, prizeSet, index) => ({ ...acc, [prizeSet.id]: prizeSetItems[index] }),
    {}
  );

  const rewardsById = rewards?.data?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
  const loyaltiesById = loyalties?.data?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

  let prizeSetCount = 0;

  for (const outcome of sortedOutcomes) {
    if (outcome.type === OUTCOME_TYPE.PRIZE_SET) {
      prizeSetCount++;

      const prizeSetOutcomes = prizeSetItemsById[outcome.id].map(prizeSetItem => {
        if ([OUTCOME_TYPE.REWARD_CAMPAIGN, OUTCOME_TYPE.REWARD].includes(prizeSetItem.item_type)) {
          return {
            ...rewardsById[prizeSetItem.item_id],
            ...prizeSetItem,
            type: OUTCOME_TYPE.REWARD,
            _id: uuid()
          };
        } else {
          return {
            ...loyaltiesById[prizeSetItem.item_id],
            ...prizeSetItem,
            type: OUTCOME_TYPE.POINT,
            _id: uuid()
          };
        }
      });

      preparedOutcomes.push({
        ...outcome,
        name: `Prize Set ${prizeSetCount}`,
        _id: uuid(),
        new_api: true,
        outcomes: prizeSetOutcomes
      });
    } else {
      preparedOutcomes.push({
        ...outcome,
        _id: uuid(),

        /**
         * To convert 'Reward::Campaign' and 'StoredValue::Campaign' to reward and points respectively
         * until backend won't accept the original types in PUT
         */
        type: outcome.type ? getOutcomeType(outcome.type).toLowerCase() : undefined
      });
    }
  }

  return preparedOutcomes;
};

export const getCampaignDetails = createAsyncThunk(
  'campaigns/getCampaignDetails',
  async (query: string | number | { campaignId: string; campaign_request_id: string }) => {
    const campaignId = get(query, 'campaignId', query);
    const campaign_request_id = get(query, 'campaign_request_id', null);

    const endpoint = campaign_request_id
      ? `/v4/dash/campaign_requests/${campaign_request_id}/campaign`
      : `/v4/dash/campaigns/${campaignId}`;
    const response = await request.get(endpoint);

    const data = campaign_request_id
      ? {
          data: {
            ...response.data?.data?.campaign,
            changedProperties: new Set(response.data?.data?.changes?.changed_attributes || [])
          }
        }
      : response.data;

    const preparedData = produce(data, async draft => {
      if (draft.data.outcomes) {
        draft.data.outcomes = await getPopulatedOutcomes(draft.data.outcomes);
      }
      if (draft.data.milestones) {
        for (let milestone of draft.data.milestones) {
          milestone.outcomes = await getPopulatedOutcomes(
            milestone.outcomes.map(outcome => ({
              ...outcome.outcome,
              count: outcome.points_count,
              gl_account_id: outcome?.campaign_options?.outcome_properties?.gl_account_id,
              gl_account_name: outcome?.campaign_options?.outcome_properties?.gl_account_name,
              gl_account_type: outcome?.campaign_options?.outcome_properties?.gl_account_type,
              campaign_options: outcome?.campaign_options
            }))
          );
        }
      }
      // https://perxtechnologies.atlassian.net/browse/FP-180
      if (draft.data.raffle_grand_prizes) {
        for (let grandPrize of draft.data.raffle_grand_prizes) {
          grandPrize.outcomes = grandPrize.campaign_module_ids?.map(id => {
            const outcome = draft.data.outcomes?.find(outcome => outcome.campaign_module_id === id);
            return _omit(outcome, 'campaign_module_id');
          });

          // Delete id and campaign_module_ids because backend removes and recreates records
          delete grandPrize.id;
          delete grandPrize.campaign_module_ids;
        }
        delete draft.data.outcomes;
      }
      // https://perxtechnologies.atlassian.net/browse/FP-181
      // campaign_code_setting param has to be undefined when create/edit a raffle with user uploaded code.
      // But on GET requests it's returned as a non-empty object. Therefore, remove it from frontend data
      if (
        draft.data.campaign_code_setting?.code_generation_method === 'multiple_with_user_upload'
      ) {
        draft.data.campaign_code_setting = undefined;
      }
    });

    return preparedData;
  }
);

//TAGS for rewards fetch here
export const tagsGet = (payload?) => {
  return {
    type: GET_TAGS,
    endpoint: `/v4/dash/simple/tags?size=1000`,
    types: [GET_TAGS, GET_TAGS_SUCCESS, GET_TAGS_FAIL],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    payload
  };
};

//CAMPAIGN ORDER
export const campaignOrder = payload => {
  return {
    type: ORDER_CAMPAIGN,
    endpoint: `/v4/dash/campaigns/ordering`,
    types: [ORDER_CAMPAIGN, ORDER_CAMPAIGN_SUCCESS, ORDER_CAMPAIGN_FAIL],
    method: 'patch',
    notification: {
      error: () => {
        message.error('Failed to Order Campaign!', DURATION_TIMER);
      }
    },
    nextAction: campaignsGet({
      search_string: payload.search_string || '',
      state: payload.campaign_type
    }),
    params: payload
  };
};

//TAGS for rewards fetch here
export const campaignsGet = (payload?) => {
  return {
    type: GET_CAMPAIGNS,
    endpoint: `/v4/dash/campaigns/`,
    types: [GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_FAIL],
    params: payload,
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    }
  };
};

//TAGS for loyalty fetch here
export const loyaltylistGet = (payload?) => {
  return {
    type: GET_LOYALTY_LIST,
    endpoint: `/v4/dash/simple/loyalty`,
    types: [GET_LOYALTY_LIST, GET_LOYALTY_LIST_SUCCESS, GET_LOYALTY_LIST_FAIL],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    params: payload
  };
};

export const rewardsGet = payload => {
  return {
    type: GET_REWARDS,
    endpoint: `/v4/dash/rewards`,
    types: [GET_REWARDS, GET_REWARDS_SUCCESS, GET_REWARDS_FAIL],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    params: payload
  };
};

//TAGS for rewards fetch here
export const audiencesGet = payload => {
  return {
    type: GET_AUDIENCES,
    endpoint: `/v4/dash/simple/audiences`,
    types: [GET_AUDIENCES, GET_AUDIENCES_SUCCESS, GET_AUDIENCES_FAIL],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    params: payload
  };
};

//TAGS for rewards fetch here
export const audienceInfoGet = payload => {
  return {
    type: GET_AUDIENCE_INFO,
    endpoint: `/v4/dash/simple/audiences`,
    types: [GET_AUDIENCE_INFO, GET_AUDIENCE_INFO_SUCCESS, GET_AUDIENCE_INFO_FAILED],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    params: payload
  };
};

export const campaignSearch = payload => {
  return {
    type: SEARCH_CAMPAIGN,
    endpoint: `/v4/dash/campaigns/`,
    types: [SEARCH_CAMPAIGN, SEARCH_CAMPAIGN_SUCCESS, SEARCH_CAMPAIGN_FAIL],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    params: payload
  };
};

export const searchReward = payload => {
  return {
    type: SEARCH_REWARD,
    endpoint: `/v4/dash/rewards`,
    types: [SEARCH_REWARD, SEARCH_REWARD_SUCCESS, SEARCH_REWARD_FAIL],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    params: payload
  };
};

export const getConditions = (payload?) => {
  return {
    type: GET_CONDITIONS,
    endpoint: `/v4/dash/simple/rule_templates`,
    types: [GET_CONDITIONS, GET_CONDITIONS_SUCCESS, GET_CONDITIONS_FAIL],
    notification: {
      error: () => {
        message.error('Failed to Load Data!', DURATION_TIMER);
      }
    },
    params: payload
  };
};

const getCampaignPreparedToBeSent = formValues => {
  let data = _cloneDeep(formValues);

  // Necessary step on the create flow.
  // Quiz and Survey are the types of games on the server side.
  // Shouldn't affect the update flow.
  if (data.campaign_type === 'quiz' || data.campaign_type === 'survey') {
    data.game_type = data.campaign_type;
    data.campaign_type = 'game';
  }

  if (!data['include_audience_file']) {
    delete data['include_audience_file'];
  }
  if (!data['exclude_audience_file']) {
    delete data['exclude_audience_file'];
  }

  if (data.rules?.length) {
    data.rules.forEach(item => {
      if (item?.labels?.length > 0) {
        item.labels = item.labels.map(label => label?.id);
      }
      if (item.conditions === undefined) {
        item.conditions = [];
      }
    });
  }

  if (data.game_type === 'quiz') {
    // TODO: remove. temp set question length as outcome position for BE
    data.outcomes = data.outcomes.map(outcome => {
      // VS-4684
      // position = 0 user gets a reward for completion, regardless of correct answers
      if (outcome.position && outcome.position > -1) {
        return outcome;
      }
      return { ...outcome, position: data.display_properties.questions.length };
    });
  }

  if (data.game_type === 'quiz' || data.game_type === 'survey') {
    // remap display_properties to display_properties_en
    data.display_properties_en = { ...data.display_properties };
    delete data.display_properties;
  }

  if (data.campaign_type === 'raffle') {
    // TODO: currently backend throws an error on request if there are any extra parameters sent.
    // Therefore, strip out frontend-only variables.
    // In the future, it probably can be removed.
    data?.raffle_grand_prizes?.forEach(prize => {
      delete prize._id;

      prize?.outcomes?.forEach(outcome => {
        delete outcome._id;
      });
    });
  }

  if (data.filters) {
    data.filters = _pickBy(data.filters, doesFilterHaveValue);
  }

  return data;
};
