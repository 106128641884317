import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import { getAbility, getViewConfig } from './actions';
import { makeSelectAuthorization, makeSelectViewConfig } from './selectors';

const actions = ['view', 'edit', 'create', 'delete'];
const sectionNames = [
  'search',
  'business_intelligence',
  'rewards',
  'campaigns',
  'catalogs',
  'loyalties',
  'merchants',
  'customer_management',
  'settings',
  'bulk_actions',
  'inventory',
  'partner'
];

const permissions = sectionNames.map(item => {
  return {
    resource_name: item,
    actions: [...actions]
  };
});
interface AbilityProps {
  children;
  auth: {
    permissions: {
      resource_name: string;
      actions: any;
      restricted_fields: string[];
      restricted_actions: string[];
    }[];
  };
  renderNoAccess;
  access;
  isComponent;
  noAccessAction;
  actions?: string[];
}
class Ability extends Component<AbilityProps> {
  static propTypes = {
    children: PropTypes.node,
    isComponent: PropTypes.bool,
    noAccessAction: PropTypes.string,
    access: PropTypes.string,
    renderNoAccess: PropTypes.func,
    actions: PropTypes.array
  };

  render() {
    // noAccessAction is useless and should be cleaned up in subsequent commits
    const { children, auth, renderNoAccess, access, isComponent, actions } = this.props;

    permissions.filter(item => {
      return (auth.permissions || []).find(dItem => dItem.resource_name === item.resource_name);
    }, auth.permissions);

    if (Object.keys(auth).length !== 0) {
      if ((auth.permissions || []).find(obj => obj.resource_name === access)) {
        if (!isComponent) {
          return children;
        } else {
          let permSec = (auth.permissions || []).find(obj => obj.resource_name === access);

          if ('actions' in permSec) {
            if (permSec.actions.length > 0) {
              let objectStyle = { pointerEvents: 'auto' };

              if (
                permSec.actions.includes('view') &&
                !permSec.actions.includes('edit') &&
                renderNoAccess
              ) {
                return renderNoAccess();
              } else if (actions?.length > 0) {
                const actionsCheck = actions.every(action => permSec.actions.includes(action));
                if (actionsCheck) {
                  //@ts-ignore
                  return <span style={objectStyle}>{children}</span>;
                } else {
                  return renderNoAccess ? renderNoAccess() : null;
                }
              } else {
                //@ts-ignore
                return <span style={objectStyle}>{children}</span>;
              }
              // return <div style={objectStyle}>{children}</div>
            } else {
              if (renderNoAccess) {
                return renderNoAccess();
              } else {
                return null;
              }
            }
          }
        }
      } else {
        if (renderNoAccess) {
          return renderNoAccess();
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuthorization,
  viewConfig: makeSelectViewConfig()
});

const mapDispatchToProps = dispatch => ({
  getAbility: () => dispatch(getAbility()),
  getViewConfig: () => dispatch(getViewConfig())
});

export default connect(mapStateToProps, mapDispatchToProps)(Ability);
